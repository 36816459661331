import type { CheckboxProps } from '@radix-ui/react-checkbox';
import React from 'react';
import { SortOrder, TableHeader, TableSortKey } from 'shared/types';

import Checkbox from './Checkbox';
import ChevronDownSmall from './icons/ChevronDownSmallIcon';
import Spinner from './Spinner';
import styles from './Table.module.css';

export const Header = ({
  coverBorder = true,
  currentSortKey,
  currentSortOder,
  headers,
  onCheckedChange,
  hasCheckbox = Boolean(onCheckedChange),
  onHeaderClick,
}: {
  coverBorder?: boolean;
  currentSortKey: TableSortKey;
  currentSortOder: SortOrder;
  hasCheckbox?: boolean;
  headers: TableHeader[];
  onCheckedChange?: CheckboxProps['onCheckedChange'];
  onHeaderClick: (key: TableSortKey) => void;
}) => {
  return (
    <thead>
      <tr className={hasCheckbox ? '' : 'no-checkbox'}>
        {hasCheckbox &&
          <th className={[styles.tableHeader, styles.checkboxWrapper].join(' ')}>
            <div className={styles.tableHeaderWrapper} style={{ width: '16px', minWidth: '16px' }}>
              <Checkbox isChecked={false} onCheckedChange={onCheckedChange} labelId="toggle-checks" />
            </div>
          </th>
        }
        {headers.map((header, index) => {
          return (
            <th
              key={header.sortkey}
              className={styles.tableHeader}
              style={{ width: header.width || 'auto', minWidth: header.minWidth ?? 0 }}
              onClick={() => onHeaderClick(header.sortkey)}
            >
              <div className={styles.tableHeaderWrapper}>
                {coverBorder && index === 0 && <div className={styles.activeBorderCover} />}
                {header.isLoading && <Spinner className={styles.headerSpinner} />}
                {header.title}
                {currentSortKey === header.sortkey &&
                  <ChevronDownSmall
                    className={currentSortOder === SortOrder.Asc ? styles.isAscending : ''}
                  />
                }
              </div>
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

export const Table = {
  Header,
};
