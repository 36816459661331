import React from 'react';

import Icon from './Icon';

export default function DeleteIcon({ className }: { className?: string; }): JSX.Element {
  return (
    <Icon text="Delete">
      <svg
        className={className}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M8 1.25C7.58579 1.25 7.25 1.58579 7.25 2C7.25 2.41421 7.58579 2.75 8 2.75H12C12.4142 2.75 12.75 2.41421 12.75 2C12.75 1.58579 12.4142 1.25 12 1.25H8Z" />
        <path d="M8.75 9C8.75 8.58579 8.41421 8.25 8 8.25C7.58579 8.25 7.25 8.58579 7.25 9V13.5C7.25 13.9142 7.58579 14.25 8 14.25C8.41421 14.25 8.75 13.9142 8.75 13.5V9Z" />
        <path d="M12.75 9C12.75 8.58579 12.4142 8.25 12 8.25C11.5858 8.25 11.25 8.58579 11.25 9V13.5C11.25 13.9142 11.5858 14.25 12 14.25C12.4142 14.25 12.75 13.9142 12.75 13.5V9Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.5 4.25C2.08579 4.25 1.75 4.58579 1.75 5C1.75 5.41421 2.08579 5.75 2.5 5.75H4.25V14C4.25 16.0711 5.92893 17.75 8 17.75H12C14.0711 17.75 15.75 16.0711 15.75 14V5.75H17.5C17.9142 5.75 18.25 5.41421 18.25 5C18.25 4.58579 17.9142 4.25 17.5 4.25H2.5ZM5.75 14V5.75H14.25V14C14.25 15.2426 13.2426 16.25 12 16.25H8C6.75736 16.25 5.75 15.2426 5.75 14Z"
        />
      </svg>
    </Icon>
  );
}
